import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Menu,
  MenuItem,
  Snackbar,
  IconButton,
  Fade,
  Backdrop
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import { MultiSelect } from 'primereact/multiselect';
import {
  calculatePercentages,
  getAllItems,
  getItemsByLocation,
  getLocations,
  updateReplenishLevel,
  resetManualReplenishLevels,
  changeReplenishToAutomatic
} from '../services/api';
import { MoreVert as MoreVertIcon, Edit as EditIcon, Autorenew as AutorenewIcon } from '@mui/icons-material';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
});

const StyledTableContainer = styled(Paper)({
  width: '90%',
  margin: 'auto',
  padding: '1rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  },
}));

function Optimize() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [itemsByLocation, setItemsByLocation] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    name: { value: null, matchMode: 'contains' },
    type: { value: null, matchMode: 'contains' },
    location_name: { value: null, matchMode: 'contains' },
    inventory_level: { value: null, matchMode: 'contains' },
    replenish_level: { value: null, matchMode: 'contains' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newReplenishLevel, setNewReplenishLevel] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [filterType, setFilterType] = useState('all');
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('optimizeActiveTab') || '0';
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem('optimizeActiveTab', activeTab);
  }, [activeTab]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [allItemsData, itemsByLocationData, locationsData] = await Promise.all([
        getAllItems(),
        getItemsByLocation(),
        getLocations(),
      ]);
      setAllItems(allItemsData);
      setItemsByLocation(itemsByLocationData);
      setLocations(locationsData);
      setSelectedLocations(locationsData.map(location => location.db_location_id));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const handleOptimize = async () => {
    setLoading(true);
    try {
      const data = await calculatePercentages();
      setResult(data);
      fetchData();
      setSnackbarMessage('Percentages calculated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error calculating percentages:', error);
      setSnackbarMessage('Failed to calculate percentages');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  const handleLocationChange = (event) => {
    setSelectedLocations(event.value);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = (tableTitle) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0">{tableTitle}</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </span>
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {activeTab === '0' ? (
            <MenuItem onClick={handleOptimize}>Calculate Percentages</MenuItem>
          ) : (
            <>
              <MenuItem onClick={handleOptimize}>Calculate Percentages</MenuItem>
              <MenuItem onClick={handleResetManualLevels}>Reset All to AI</MenuItem>
              <MenuItem onClick={() => handleFilterChange('all')}>Show All</MenuItem>
              <MenuItem onClick={() => handleFilterChange('automatic')}>Filter by Automatic</MenuItem>
              <MenuItem onClick={() => handleFilterChange('manual')}>Filter by Manual</MenuItem>
            </>
          )}
        </Menu>
      </div>
    );
  };

  const handleResetManualLevels = async () => {
    try {
      const confirmReset = window.confirm("Are you sure you want to reset all manual replenish levels to AI? This action cannot be undone.");
      if (confirmReset) {
        await resetManualReplenishLevels();
        setSnackbarMessage('All manual replenish levels have been reset to AI');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        fetchData(); // Refresh data after reset
      }
    } catch (error) {
      console.error('Error resetting manual levels:', error);
      setSnackbarMessage('Failed to reset manual replenish levels');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    handleMenuClose();
  };

  const handleFilterChange = (filterType) => {
    setFilterType(filterType);
    handleMenuClose();
  };

  const filteredItemsByLocation = React.useMemo(() => {
    return itemsByLocation.filter(item => {
      const locationMatch = selectedLocations.includes(item.location_id);
      const filterMatch = filterType === 'all' ||
        (filterType === 'automatic' && !item.manual_item_replenish) ||
        (filterType === 'manual' && item.manual_item_replenish);
      return locationMatch && filterMatch;
    });
  }, [itemsByLocation, selectedLocations, filterType]);

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setNewReplenishLevel(item.replenish_level);
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedItem(null);
  };

  const handleReplenishLevelChange = (event) => {
    setNewReplenishLevel(event.target.value);
  };

  const handleConfirmEdit = async () => {
    try {
      await updateReplenishLevel(selectedItem.item_id, selectedItem.location_id, newReplenishLevel);
      setSnackbarMessage('Replenish level updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData(); // Refresh data after update
      handleDialogClose();
    } catch (error) {
      console.error('Error updating replenish level:', error);
      setSnackbarMessage('Failed to update replenish level');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeToAutomatic = async (itemId, locationId) => {
    try {
      await changeReplenishToAutomatic(itemId, locationId);
      setSnackbarMessage('Replenish level changed to automatic successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData(); // Refresh data after update
    } catch (error) {
      console.error('Error changing replenish to automatic:', error);
      setSnackbarMessage('Failed to change replenish to automatic');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <StyledContainer>
      <TabView 
        style={{ width: '100%', marginTop: '2rem' }} 
        activeIndex={parseInt(activeTab)} 
        onTabChange={(e) => setActiveTab(e.index.toString())}
      >
        <TabPanel header="All Items">
          <StyledTableContainer>
            <DataTable
              value={allItems}
              paginator
              rows={10}
              dataKey="id"
              filters={filters}
              filterDisplay="menu"
              loading={loading}
              globalFilterFields={['name', 'type']}
              header={renderHeader('All Items')}
              emptyMessage="No items found."
              responsiveLayout="scroll"
            >
              <Column field="name" header="Name" filter filterPlaceholder="Search by name" sortable />
              <Column field="type" header="Type" filter filterPlaceholder="Search by type" sortable />
              <Column field="size" header="Size" sortable />
              <Column field="brim" header="Brim" sortable />
              <Column field="shape" header="Shape" sortable />
              <Column field="total_inventory" header="Total Inventory" sortable />
              <Column field="total_replenish_level" header="Total Replenish Level" sortable />
              <Column field="percent_of_total" header="% of Total" sortable body={(rowData) => `${rowData.percent_of_total}%`} />
              <Column field="percent_of_type" header="% of Type" sortable body={(rowData) => `${rowData.percent_of_type}%`} />
              <Column field="total_sales" header="Total Sales" sortable />
            </DataTable>
          </StyledTableContainer>
        </TabPanel>
        <TabPanel header="Items by Location">
          <FormControl variant="outlined" style={{ margin: '1rem', minWidth: 200 }}>
            <MultiSelect
              value={selectedLocations}
              options={locations}
              onChange={handleLocationChange}
              optionLabel="name"
              optionValue="db_location_id"
              placeholder="Locations"
              className="w-full md:w-20rem"
            />
          </FormControl>
          <StyledTableContainer>
            <DataTable
              value={filteredItemsByLocation}
              paginator
              rows={10}
              dataKey="id"
              filters={filters}
              filterDisplay="menu"
              loading={loading}
              globalFilterFields={['item_name', 'item_type', 'location_name', 'inventory_level', 'replenish_level']}
              header={renderHeader('Items by Location')}
              emptyMessage="No items found."
              responsiveLayout="scroll"
            >
              <Column field="item_name" header="Name" filter filterPlaceholder="Search by name" sortable />
              <Column field="item_type" header="Type" filter filterPlaceholder="Search by type" sortable />
              <Column field="location_name" header="Location" filter filterPlaceholder="Search by location" sortable />
              <Column field="inventory_level" header="Inventory Level" filter filterPlaceholder="Search by inventory" sortable />
              <Column field="replenish_level" header="Replenish Level" filter filterPlaceholder="Search by replenish" sortable />
              <Column
                header="Actions"
                body={(rowData) => (
                  <>
                    <IconButton color="primary" onClick={() => handleEditClick(rowData)}>
                      <EditIcon />
                    </IconButton>
                    {rowData.manual_item_replenish && (
                      <IconButton color="secondary" onClick={() => handleChangeToAutomatic(rowData.item_id, rowData.location_id)}>
                        <AutorenewIcon />
                      </IconButton>
                    )}
                  </>
                )}
              />
            </DataTable>
          </StyledTableContainer>
        </TabPanel>
      </TabView>

      <StyledDialog 
        open={editDialogOpen} 
        onClose={handleDialogClose}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Edit Replenish Level</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the replenish level for {selectedItem?.item_name}.
            <br />
            This level will remain manual until changed to AI-automatic.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Replenish Level"
            type="number"
            fullWidth
            value={newReplenishLevel}
            onChange={handleReplenishLevelChange}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmEdit} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </StyledDialog>

      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert 
          elevation={6} 
          variant="filled" 
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </StyledContainer>
  );
}

export default Optimize;
