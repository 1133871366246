import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Terms of Service for PIM BY ELI Internal Business Application
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms of Service govern your use of the internal business application of PIM BY ELI. Please read these terms carefully to understand your rights and obligations.
      </Typography>
      <Typography variant="h5" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing and using this internal application, you agree to these Terms of Service. If you do not agree with any of these terms, please do not use this application.
      </Typography>
      <Typography variant="h5" gutterBottom>
        2. Authorized Use
      </Typography>
      <Typography variant="body1" paragraph>
        This application is intended for use by authorized employees, contractors, and stakeholders of PIM BY ELI only. Unauthorized access or use of this application is strictly prohibited and may result in disciplinary or legal action.
      </Typography>
      <Typography variant="body1" paragraph>
        Access is granted only to:
      </Typography>
      <ul>
        <li>Employees who need it for their official duties.</li>
        <li>Contractors and stakeholders explicitly approved by the IT department.</li>
        <li>No other individuals are authorized to use or gain access to this tool.</li>
      </ul>
      <Typography variant="h5" gutterBottom>
        3. User Responsibilities
      </Typography>
      <Typography variant="body1" paragraph>
        You are responsible for maintaining the security and confidentiality of your login credentials. Misuse of credentials, sharing passwords, or attempting unauthorized access to systems is strictly forbidden.
      </Typography>
      <Typography variant="body1" paragraph>
        All actions performed using your account will be assumed to be conducted by you. You agree to:
      </Typography>
      <ul>
        <li>Notify the IT department at <strong>support@pimbyeli.com</strong> immediately if you suspect your account has been compromised.</li>
        <li>Use the system solely for business-related activities.</li>
        <li>Respect and maintain the privacy and integrity of company data.</li>
      </ul>
      <Typography variant="h5" gutterBottom>
        4. Prohibited Conduct
      </Typography>
      <Typography variant="body1" paragraph>
        Users must not:
      </Typography>
      <ul>
        <li>Attempt to gain unauthorized access to systems or data beyond their assigned permissions.</li>
        <li>Engage in any activity that disrupts the performance or security of the application.</li>
        <li>Modify, delete, or copy data without explicit permission.</li>
      </ul>
      <Typography variant="h5" gutterBottom>
        5. Termination of Access
      </Typography>
      <Typography variant="body1" paragraph>
        PIM BY ELI reserves the right to terminate or suspend your access to the application at any time, with or without cause or notice. This includes, but is not limited to, violations of these Terms of Service or suspicious behavior that compromises system integrity.
      </Typography>
      <Typography variant="h5" gutterBottom>
        6. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        This internal application is provided "as is" for use by authorized personnel. PIM BY ELI makes no guarantees regarding uptime or performance. In no event will PIM BY ELI be liable for any damages resulting from the use or inability to use this application, including unauthorized access or data loss.
      </Typography>
      <Typography variant="h5" gutterBottom>
        7. Modifications to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        PIM BY ELI may modify these Terms of Service at any time. Any changes will be communicated through official company channels or upon your next login. Continued use of the application after changes have been communicated will indicate your acceptance of those changes.
      </Typography>
      <Typography variant="h5" gutterBottom>
        8. Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have questions or concerns regarding these Terms of Service, please contact us at <strong>support@pimbyeli.com</strong>.
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing this application, you agree to comply with these Terms of Service and all applicable laws and regulations. Unauthorized use of this system is strictly prohibited and will result in appropriate disciplinary or legal action.
      </Typography>
    </Container>
  );
};

export default TermsOfService;