import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Privacy Policy for PIM BY ELI Internal Business Application
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to the internal business application of PIM BY ELI. This Privacy Policy explains how we handle personal information collected through your use of this internal tool. We are committed to ensuring your privacy and the safety of your personal data.
      </Typography>
      <Typography variant="h5" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We collect various types of personal information in connection with the use of this application. This may include, but is not limited to:
      </Typography>
      <ul>
        <li>Your name</li>
        <li>Email address</li>
        <li>Log-in credentials and related security information</li>
      </ul>
      <Typography variant="body1" paragraph>
        This information is collected to verify your identity and ensure that only authorized personnel have access to our internal tools and resources.
      </Typography>
      <Typography variant="h5" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        The information collected is used strictly for business operations related to PIM BY ELI:
      </Typography>
      <ul>
        <li>To authenticate users and grant appropriate access to our internal business systems.</li>
        <li>To ensure the security of company resources by tracking login activities.</li>
        <li>To improve and maintain the performance of our internal tools.</li>
      </ul>
      <Typography variant="body1" paragraph>
        We may also use this information to contact you with important security or access-related information. No promotional or marketing communication will be sent based on the information collected through this platform.
      </Typography>
      <Typography variant="h5" gutterBottom>
        3. Data Sharing and Disclosure
      </Typography>
      <Typography variant="body1" paragraph>
        Your personal data is confidential. We do not share your information with third-party companies unless required by:
      </Typography>
      <ul>
        <li>Legal obligations, such as to comply with applicable law, regulation, or court order.</li>
        <li>Internal purposes like compliance, audit, or information technology security.</li>
      </ul>
      <Typography variant="body1" paragraph>
        Rest assured that all information stays within the secure, internal environment of PIM BY ELI, where access is limited to only those with a specific business need.
      </Typography>
      <Typography variant="h5" gutterBottom>
        4. Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We take the security of your information seriously. We have implemented strong administrative, technical, and physical safeguards to ensure your personal data remains protected from unauthorized access, alteration, or misuse.
      </Typography>
      <Typography variant="h5" gutterBottom>
        5. Retention of Information
      </Typography>
      <Typography variant="body1" paragraph>
        We retain your information only for as long as it is needed to fulfill the purposes outlined above, or as required by law. Once no longer required, your data is securely deleted or anonymized.
      </Typography>
      <Typography variant="h5" gutterBottom>
        6. Your Rights and Control Over Information
      </Typography>
      <Typography variant="body1" paragraph>
        You have the right to:
      </Typography>
      <ul>
        <li>Request access to the personal information we hold about you.</li>
        <li>Request correction of inaccuracies in your personal information.</li>
        <li>Request removal or deletion of personal information where it is no longer required for legitimate purposes.</li>
      </ul>
      <Typography variant="body1" paragraph>
        If you need assistance or have any questions regarding your personal information, please contact us at <strong>support@pimbyeli.com</strong>.
      </Typography>
      <Typography variant="h5" gutterBottom>
        7. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        This Privacy Policy may be updated from time to time to reflect changes in our practices or applicable laws. You will be notified of any significant updates through your registered email or upon your next login.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about our Privacy Policy, please contact <strong>support@pimbyeli.com</strong>.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;