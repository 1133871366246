import React, { useState, useEffect } from 'react';
import { 
  Container, Typography, Paper, List, ListItem, ListItemText, Button, 
  ListItemSecondaryAction, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchDeletedLocations, recoverLocation } from '../services/api';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

function DeletedLocations() {
  const [deletedLocations, setDeletedLocations] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDeletedLocations();
  }, []);

  const getDeletedLocations = async () => {
    try {
      setLoading(true);
      console.log('Attempting to fetch deleted locations');
      const data = await fetchDeletedLocations(); // Ensure this function is called
      console.log(`Received deleted locations data: ${JSON.stringify(data)}`);
      setDeletedLocations(data);
      setError(null);
    } catch (err) {
      console.error(`Error fetching deleted locations: ${err.message}`, err);
      setError('Failed to fetch deleted locations. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleRecover = async (locationId) => {
    try {
      setLoading(true);
      await recoverLocation(locationId);
      console.log(`Successfully recovered location with ID: ${locationId}`);
      // Refresh the list of deleted locations
      await getDeletedLocations();
    } catch (err) {
      console.error(`Error recovering location: ${err.message}`, err);
      setError('Failed to recover location. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <StyledContainer>
        <CircularProgress />
      </StyledContainer>
    );
  }

  if (error) {
    return (
      <StyledContainer>
        <Typography color="error">{error}</Typography>
        <Button onClick={getDeletedLocations} variant="contained" color="primary" style={{ marginTop: '1rem' }}>
          Retry
        </Button>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        Deleted Locations
      </Title>
      <StyledPaper>
        {deletedLocations.length === 0 ? (
          <Typography>No deleted locations found.</Typography>
        ) : (
          <List>
            {deletedLocations.map((location) => (
              <ListItem key={location.db_location_id}>
                <ListItemText 
                  primary={location.name} 
                  secondary={`Deleted on: ${new Date(location.last_updated).toLocaleString()}`} 
                />
                <ListItemSecondaryAction>
                  <Button 
                    onClick={() => handleRecover(location.db_location_id)}
                    color="primary"
                    variant="contained"
                    disabled={loading}
                  >
                    Recover
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </StyledPaper>
    </StyledContainer>
  );
}

export default DeletedLocations;