import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  getLocationById, 
  updateReplenishLevelsById, 
  getReplenishMode, 
  updateReplenishMode, 
  updateAutoReplenishSettings,
  getSalesDataForLocation,
  getLocationsWithSalesData,
  calculateAutoReplenishLevels,
  getAutoReplenishDetails
} from '../services/api';
import { 
  Container, Typography, Grid, Card, CardContent, TextField, 
  IconButton, Box, CircularProgress, Switch, FormControlLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  RadioGroup, Radio, FormControl, FormLabel, Modal, Button, Checkbox, FormGroup, List, ListItem, ListItemText, ListItemSecondaryAction,
  Backdrop, Fade, Tooltip
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { styled } from '@mui/material/styles';
import CustomButton from './CustomButton';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';

// Styled components (keep existing styled components and add/modify these)
const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#ffffff',
  borderRadius: 16,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
  padding: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: '#2c3e50',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 700,
  fontSize: '2rem',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  maxHeight: '90vh',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: '16px', // More rounded edges
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  borderRadius: '20px', // More rounded button edges
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledList = styled(List)(({ theme }) => ({
  maxHeight: 200,
  overflowY: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

// ... (keep other existing code)

function ReplenishLevels() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [editingReplenishLevels, setEditingReplenishLevels] = useState({});
  const [isAutomatic, setIsAutomatic] = useState(false);
  const [showAutoReplenishModal, setShowAutoReplenishModal] = useState(false);
  const [salesData, setSalesData] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [useAllLocations, setUseAllLocations] = useState(false);
  const [isSalesSufficient, setIsSalesSufficient] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState('inventory');
  const [inventoryAmount, setInventoryAmount] = useState('');
  const [monthsOfSales, setMonthsOfSales] = useState('');
  const [autoReplenishDetails, setAutoReplenishDetails] = useState(null);
  const [calculating, setCalculating] = useState(false);
  const [step, setStep] = useState(1); // New state to manage modal steps

  useEffect(() => {
    fetchLocationDetails();
  }, [id]);

  const fetchLocationDetails = async () => {
    try {
      setLoading(true);
      const locationData = await getLocationById(id);
      setLocation(locationData);
      setEditingReplenishLevels(locationData.ReplenishLevels || {});
      const replenishMode = await getReplenishMode(id);
      setIsAutomatic(replenishMode.is_automatic);
      if (replenishMode.is_automatic) {
        fetchAutoReplenishDetails();
      }
    } catch (err) {
      console.error('Error fetching location details:', err);
      setError('Failed to fetch location details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchAutoReplenishDetails = async () => {
    try {
      const details = await getAutoReplenishDetails(id);
      setAutoReplenishDetails(details);
    } catch (err) {
      console.error('Error fetching auto replenish details:', err);
      setError('Failed to fetch auto replenish details.');
    }
  };

  const handleReplenishLevelChange = (category, value) => {
    setEditingReplenishLevels(prev => ({
      ...prev,
      [category]: value === '' ? '' : parseInt(value, 10)
    }));
  };

  const resetReplenishLevels = () => {
    setEditingReplenishLevels(location.ReplenishLevels || {});
  };

  const saveReplenishLevels = async () => {
    try {
      await updateReplenishLevelsById(id, editingReplenishLevels);
      setMessage('Replenish levels updated successfully.');
      fetchLocationDetails();
    } catch (err) {
      console.error('Error updating replenish levels:', err);
      setError('Failed to update replenish levels.');
    }
  };

  const handleModeChange = async (event) => {
    const newMode = event.target.checked;
    try {
      await updateReplenishMode(id, newMode);
      setIsAutomatic(newMode);
      if (newMode) {
        setShowAutoReplenishModal(true);
        fetchCurrentLocationSales();
      }
    } catch (err) {
      console.error('Error updating replenish mode:', err);
      setError('Failed to update replenish mode.');
    }
  };

  const handleSettingsClick = () => {
    setShowAutoReplenishModal(true);
    fetchCurrentLocationSales();
  };

  const fetchCurrentLocationSales = async () => {
    try {
      const data = await getSalesDataForLocation(id);
      setSalesData(data);
    } catch (err) {
      console.error('Error fetching sales data:', err);
      setError('Failed to fetch sales data.');
    }
  };

  const handleSalesSufficiency = (sufficient) => {
    if (sufficient) {
      setStep(3); // Skip location selection step
    } else {
      setIsSalesSufficient(false);
      fetchAllLocations();
      setStep(2);
    }
  };

  const fetchAllLocations = async () => {
    try {
      const locations = await getLocationsWithSalesData();
      setAllLocations(locations);
    } catch (err) {
      console.error('Error fetching locations with sales data:', err);
      setError('Failed to fetch locations with sales data.');
    }
  };

  const handleLocationSelection = (event, locationId) => {
    if (event.target.checked) {
      setSelectedLocations(prev => [...prev, locationId]);
    } else {
      setSelectedLocations(prev => prev.filter(id => id !== locationId));
    }
  };

  const handleCalculateAutoReplenish = async () => {
    try {
      setCalculating(true);
      const params = {
        locationId: id,
        selectedLocations: useAllLocations ? 'all' : selectedLocations,
        calculationMethod,
        inventoryAmount,
        monthsOfSales
      };
      const result = await calculateAutoReplenishLevels(params);
      setEditingReplenishLevels(result.updatedLevels);
      setShowAutoReplenishModal(false);
      setMessage('Auto replenish levels calculated and applied successfully.');
      
      // Refresh the page data
      await fetchLocationDetails();
      await fetchAutoReplenishDetails();
    } catch (error) {
      console.error('Error calculating auto replenish levels:', error);
      setError('Failed to calculate auto replenish levels.');
    } finally {
      setCalculating(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!location) return <Typography color="error">Location not found</Typography>;

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        Replenish Levels for {location.name}
      </Title>
      {message && <Typography color="secondary" gutterBottom>{message}</Typography>}
      {error && <Typography color="error" gutterBottom>{error}</Typography>}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAutomatic}
                      onChange={handleModeChange}
                      name="replenishMode"
                      color="primary"
                    />
                  }
                  label={isAutomatic ? "Automatic Mode" : "Manual Mode"}
                />
                {isAutomatic && (
                  <Tooltip title="Configure automatic replenish settings">
                    <IconButton onClick={handleSettingsClick} color="primary">
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              {isAutomatic && autoReplenishDetails && (
                <Box sx={{ mb: 2, backgroundColor: '#f5f5f5', p: 2, borderRadius: 1 }}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Current Settings:</strong>
                  </Typography>
                  <Typography variant="body2">
                    Using {autoReplenishDetails.useAllLocations 
                      ? 'all locations' 
                      : autoReplenishDetails.preferredLocations.length > 0 
                        ? `${autoReplenishDetails.preferredLocations.length} locations` 
                        : 'own location'}
                  </Typography>
                  <Typography variant="body2">
                    Based on: {autoReplenishDetails.calculationMethod === 'sales' 
                      ? `${autoReplenishDetails.monthsOfSales} months of sales` 
                      : `${autoReplenishDetails.inventoryAmount} total inventory`}
                  </Typography>
                </Box>
              )}
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table aria-label="replenish levels table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Current Inventory</TableCell>
                      <TableCell>Replenish Level</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {['G', 'M', 'G2', 'M2'].map((category) => (
                      <TableRow key={category}>
                        <TableCell>{category}</TableCell>
                        <TableCell>{location.InventoryByCategory[category]}</TableCell>
                        <TableCell>
                          {isAutomatic ? (
                            editingReplenishLevels[category]
                          ) : (
                            <TextField
                              type="number"
                              value={editingReplenishLevels[category] ?? ''}
                              onChange={(e) => handleReplenishLevelChange(category, e.target.value)}
                              size="small"
                              fullWidth
                              inputProps={{ min: 0 }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!isAutomatic && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Tooltip title="Reset to original values">
                    <IconButton onClick={resetReplenishLevels} color="secondary">
                      <UndoIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Save changes">
                    <IconButton onClick={saveReplenishLevels} color="primary">
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <CustomButton onClick={() => navigate(`/locations/${id}`)}>
          Back to Location Details
        </CustomButton>
      </Box>

      <Modal
        open={showAutoReplenishModal}
        onClose={() => {
          setShowAutoReplenishModal(false);
          setStep(1);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showAutoReplenishModal}>
          <ModalContent>
            <Typography variant="h5" gutterBottom>
              Automatic Replenishment Settings
            </Typography>

            {step === 1 && salesData && (
              <Box sx={{ mb: 3, backgroundColor: '#f5f5f5', p: 2, borderRadius: '12px' }}>
                <Typography variant="body1" gutterBottom>
                  <strong>Current Total Sales:</strong> {
                    typeof salesData.totalSales === 'number' 
                      ? salesData.totalSales.toFixed(0)
                      : `${salesData.totalSales || 'N/A'}`
                  } items
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Is this sales amount sufficient for calculating replenishment levels?
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                  <StyledButton variant="contained" color="primary" onClick={() => handleSalesSufficiency(true)}>
                    Yes, proceed
                  </StyledButton>
                  <StyledButton variant="outlined" color="secondary" onClick={() => handleSalesSufficiency(false)}>
                    No, choose additional data
                  </StyledButton>
                </Box>
              </Box>
            )}

            {step === 2 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Select Locations for Data
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useAllLocations}
                      onChange={(e) => setUseAllLocations(e.target.checked)}
                    />
                  }
                  label="Use all locations"
                />
                {!useAllLocations && (
                  <StyledList>
                    {allLocations.map((loc) => (
                      <ListItem key={loc.db_location_id} dense divider>
                        <ListItemText primary={loc.name} secondary={`Sales: ${loc.sales.toFixed(0)} items`} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={(e) => handleLocationSelection(e, loc.db_location_id)}
                            checked={selectedLocations.includes(loc.db_location_id)}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </StyledList>
                )}
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <StyledButton variant="contained" color="primary" onClick={() => setStep(3)}>
                    Next
                  </StyledButton>
                </Box>
              </Box>
            )}

            {step === 3 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Replenishment Calculation Method
                </Typography>
                <StyledFormControl component="fieldset">
                  <FormLabel component="legend">Replenish Based On:</FormLabel>
                  <RadioGroup
                    row
                    value={calculationMethod}
                    onChange={(e) => setCalculationMethod(e.target.value)}
                  >
                    <FormControlLabel value="inventory" control={<Radio />} label="Inventory Amount" />
                    <FormControlLabel value="sales" control={<Radio />} label="Months of Sales" />
                  </RadioGroup>
                </StyledFormControl>

                {calculationMethod === 'inventory' && (
                  <TextField
                    label="Inventory Amount"
                    type="number"
                    value={inventoryAmount}
                    onChange={(e) => setInventoryAmount(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
                {calculationMethod === 'sales' && (
                  <TextField
                    label="Months of Sales"
                    type="number"
                    value={monthsOfSales}
                    onChange={(e) => setMonthsOfSales(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <StyledButton variant="outlined" onClick={() => setStep(isSalesSufficient ? 1 : 2)}>
                    Back
                  </StyledButton>
                  <StyledButton 
                    variant="contained" 
                    onClick={handleCalculateAutoReplenish}
                    disabled={calculating}
                  >
                    {calculating ? <CircularProgress size={24} /> : 'Calculate and Apply'}
                  </StyledButton>
                </Box>
              </Box>
            )}
          </ModalContent>
        </Fade>
      </Modal>

      {/* ... (keep existing Snackbar components) */}
    </StyledContainer>
  );
}

export default ReplenishLevels;
