import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box, Button, Paper, InputAdornment } from '@mui/material';
import { Delete, Add, Warning as WarningIcon, Store, Link as LinkIcon, VpnKey, InfoOutlined, PointOfSale } from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';
import { getPosSystems, deletePos, addPos } from '../services/api';
import CustomButton from './CustomButton';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#ffffff',
  borderRadius: 8,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
  padding: theme.spacing(3),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#2c3e50',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 700,
  fontSize: '2rem',
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const WarningDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2),
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  },
}));

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  fontSize: 48,
  color: theme.palette.warning.main,
  marginBottom: theme.spacing(2),
}));

const typeAnimation = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const AnimatedText = styled(Typography)(({ theme, delay = 0 }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  animation: `${typeAnimation} 2s steps(40, end) ${delay}s forwards`,
  width: 0,
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '1rem',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    overflow: 'hidden',
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '1.5rem',
    fontWeight: 600,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    backgroundColor: '#f8fafc',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
    backgroundColor: '#f8fafc',
    borderTop: '1px solid #e2e8f0',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    backgroundColor: '#ffffff',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid #e2e8f0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.15)',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#64748b',
    fontWeight: 500,
  },
  '& .MuiInputBase-input': {
    padding: '16px',
  },
}));

const DialogButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '12px 24px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '0.95rem',
  boxShadow: 'none',
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0 4px 6px rgba(66, 153, 225, 0.2)',
    },
  },
  '&.MuiButton-outlined': {
    borderColor: '#e2e8f0',
    color: '#64748b',
    '&:hover': {
      backgroundColor: '#f8fafc',
      borderColor: '#cbd5e1',
    },
  },
}));

function POSManagement() {
  const [posSystems, setPosSystems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedPos, setSelectedPos] = useState(null);
  const [newPos, setNewPos] = useState({ name: '', current_api_url: '', current_api_key: '' });

  useEffect(() => {
    fetchPosSystems();
  }, []);

  const fetchPosSystems = async () => {
    try {
      const response = await getPosSystems();
      setPosSystems(response.data);
    } catch (error) {
      console.error('Error fetching POS systems:', error);
    }
  };

  const handleDeleteClick = (pos) => {
    setSelectedPos(pos);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      console.log(`Attempting to delete POS with ID: ${selectedPos.pos_id}`);
      await deletePos(selectedPos.pos_id);
      setPosSystems(posSystems.filter(pos => pos.pos_id !== selectedPos.pos_id));
      setOpenDialog(false);
    } catch (error) {
      console.error('Error deleting POS system:', error);
      alert('Failed to delete POS system. Please try again later.');
    }
  };

  const handleAddPos = () => {
    return (
      <StyledDialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          <PointOfSale /> Add New POS System
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Connect a new Point of Sale system to your inventory management
            </Typography>
          </Box>
          <StyledTextField
            autoFocus
            label="POS Name"
            fullWidth
            value={newPos.name}
            onChange={(e) => setNewPos({ ...newPos, name: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Store sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            label="API URL"
            fullWidth
            value={newPos.current_api_url}
            onChange={(e) => setNewPos({ ...newPos, current_api_url: e.target.value })}
            helperText="Enter the base URL for the POS API"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            label="API Key"
            fullWidth
            value={newPos.current_api_key}
            onChange={(e) => setNewPos({ ...newPos, current_api_key: e.target.value })}
            type="password"
            helperText="Enter the authentication key for API access"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKey sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
          />
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2, 
              backgroundColor: '#f1f5f9',
              border: '1px solid #e2e8f0',
              borderRadius: 3
            }}
          >
            <Typography variant="subtitle2" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <InfoOutlined fontSize="small" /> Connection Information
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Make sure you have the following information from your POS provider:
              <Box component="ul" sx={{ mt: 1, mb: 0 }}>
                <li>API base URL (usually starts with https://)</li>
                <li>Valid API key or token for authentication</li>
                <li>Necessary permissions configured in your POS system</li>
              </Box>
            </Typography>
          </Paper>
        </DialogContent>
        <DialogActions>
          <DialogButton 
            onClick={() => setOpenAddDialog(false)}
            variant="outlined"
          >
            Cancel
          </DialogButton>
          <DialogButton
            onClick={handleAddPos}
            variant="contained"
            disabled={!newPos.name || !newPos.current_api_url || !newPos.current_api_key}
            startIcon={<Add />}
          >
            Connect POS System
          </DialogButton>
        </DialogActions>
      </StyledDialog>
    );
  };

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Title variant="h4">POS Management</Title>
        <AddButton
          startIcon={<Add />}
          onClick={() => setOpenAddDialog(true)}
          variant="contained"
        >
          Add POS System
        </AddButton>
      </Box>
      <Grid container spacing={3}>
        {posSystems.map((pos) => (
          <Grid item xs={12} sm={6} md={4} key={pos.pos_id}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h2">
                  {pos.name}
                </Typography>
                <Typography color="textSecondary">
                  URL: <StyledLink href={pos.current_api_url} target="_blank" rel="noopener noreferrer">
                    {pos.current_api_url}
                  </StyledLink>
                </Typography>
              </CardContent>
              <IconButton
                onClick={() => handleDeleteClick(pos)}
                color="error"
                style={{ alignSelf: 'flex-end', margin: '8px' }}
              >
                <Delete />
              </IconButton>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <WarningDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          <Box display="flex" alignItems="center" flexDirection="column">
            <StyledWarningIcon />
            <Typography variant="h6">Warning: Permanent Deletion</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AnimatedText variant="body1" paragraph delay={0}>
            You are about to delete the POS system "{selectedPos?.name}".
          </AnimatedText>
          <AnimatedText variant="body1" paragraph delay={2}>
            This action will permanently delete:
          </AnimatedText>
          <Box component="ul" sx={{ padding: 0 }}>
            <AnimatedText component="li" delay={4}>All associated locations</AnimatedText>
            <AnimatedText component="li" delay={5}>All items in these locations</AnimatedText>
            <AnimatedText component="li" delay={6}>All replenishment levels and history</AnimatedText>
            <AnimatedText component="li" delay={7}>All related settings and data</AnimatedText>
          </Box>
          <AnimatedText variant="body1" paragraph delay={9}>
            This action cannot be undone. Are you absolutely sure?
          </AnimatedText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenDialog(false)}>Cancel</CustomButton>
          <CustomButton onClick={handleDeleteConfirm} color="error">
            I understand, delete everything
          </CustomButton>
        </DialogActions>
      </WarningDialog>

      <StyledDialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add New POS System</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="POS Name"
            fullWidth
            value={newPos.name}
            onChange={(e) => setNewPos({ ...newPos, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="API URL"
            fullWidth
            value={newPos.current_api_url}
            onChange={(e) => setNewPos({ ...newPos, current_api_url: e.target.value })}
          />
          <TextField
            margin="dense"
            label="API Key"
            fullWidth
            value={newPos.current_api_key}
            onChange={(e) => setNewPos({ ...newPos, current_api_key: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenAddDialog(false)}>Cancel</CustomButton>
          <CustomButton onClick={handleAddPos} color="primary" variant="contained">Add</CustomButton>
        </DialogActions>
      </StyledDialog>
    </StyledContainer>
  );
}

export default POSManagement;
