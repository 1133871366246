import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getLocationById } from '../services/api';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const EmbeddedIframe = styled('iframe')({
  width: '100%',
  height: 'calc(100vh - 200px)',
  border: 'none',
});

function EmbeddedSheet() {
  const { id, sheetType } = useParams();
  const [sheetUrl, setSheetUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocationDetails = async () => {
      try {
        const locationData = await getLocationById(id);
        let url = '';
        switch (sheetType) {
          case 'inventory':
            url = locationData.InventorySheet;
            break;
          case 'replenish':
            url = locationData.RecommendedInventorySheet;
            break;
          case 'order':
            url = locationData.OrderSheet;
            break;
          default:
            throw new Error('Invalid sheet type');
        }
        setSheetUrl(url);
      } catch (err) {
        console.error('Error fetching location details:', err);
        setError('Failed to fetch sheet URL. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchLocationDetails();
  }, [id, sheetType]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <StyledContainer>
      <Typography variant="h4" gutterBottom>
        {sheetType.charAt(0).toUpperCase() + sheetType.slice(1)} Sheet
      </Typography>
      <Box mb={2}>
        <Typography variant="body1">
          Location ID: {id}
        </Typography>
      </Box>
      {sheetUrl ? (
        <EmbeddedIframe src={sheetUrl} />
      ) : (
        <Typography variant="body1" color="error">
          Sheet not found or unavailable.
        </Typography>
      )}
    </StyledContainer>
  );
}

export default EmbeddedSheet;