import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, loginWithVerification, verifyCode } from '../services/api';
import { Container, TextField, Button, Typography, Paper, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';

const LOGIN_WITHOUT_EMAIL_ENABLED = false;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(135deg, var(--color-primary), var(--color-secondary))',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  maxWidth: 450,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  borderRadius: 20,
  boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontSize: '2.8rem',
  fontWeight: 700,
  color: 'var(--color-primary)',
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  letterSpacing: '0.05em',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: 'var(--color-primary)',
  fontWeight: 600,
  textAlign: 'center',
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: 'red',
  marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'var(--color-primary)',
    },
    '&:hover fieldset': {
      borderColor: 'var(--color-secondary)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--color-accent)',
    },
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const LoginButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontWeight: 600,
  borderRadius: 30,
  textTransform: 'none',
  fontSize: '1rem',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const RegularLoginButton = styled(LoginButton)(({ theme }) => ({
  backgroundColor: 'var(--color-primary)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'var(--color-primary-light)',
    color: 'var(--color-primary-dark)', // Darker text color on hover
  },
}));

const VerificationLoginButton = styled(LoginButton)(({ theme }) => ({
  backgroundColor: 'var(--color-accent)',
  color: 'var(--color-primary)',
  '&:hover': {
    backgroundColor: 'var(--color-accent-light)',
  },
}));

const WarningText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontSize: '0.9rem',
}));

const QuoteBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'center',
}));

const QuoteText = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  marginBottom: theme.spacing(1),
}));

const QuoteAuthor = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

const quotes = [
  
  {
    "quote": "Success usually comes to those who are too busy to be looking for it.",
    "author": "Henry David Thoreau"
  },
  {
    "quote": "Believe you can and you're halfway there.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Life is 10% what happens to us and 90% how we react to it.",
    "author": "Charles R. Swindoll"
  },
  {
    "quote": "The only limit to our realization of tomorrow is our doubts of today.",
    "author": "Franklin D. Roosevelt"
  },
  {
    "quote": "Don't be afraid to give up the good to go for the great.",
    "author": "John D. Rockefeller"
  },
  {
    "quote": "I find that the harder I work, the more luck I seem to have.",
    "author": "Thomas Jefferson"
  },
  {
    "quote": "Success is not in what you have, but who you are.",
    "author": "Bo Bennett"
  },
  {
    "quote": "It does not matter how slowly you go as long as you do not stop.",
    "author": "Confucius"
  },
  {
    "quote": "Difficulties in life are intended to make us better, not bitter.",
    "author": "Dan Reeves"
  },
  {
    "quote": "Success is not the key to happiness. Happiness is the key to success.",
    "author": "Albert Schweitzer"
  },
  {
    "quote": "Your time is limited, don't waste it living someone else's life.",
    "author": "Steve Jobs"
  },
  {
    "quote": "A journey of a thousand miles begins with a single step.",
    "author": "Lao Tzu"
  },
  {
    "quote": "To succeed in life, you need three things: a wishbone, a backbone, and a funny bone.",
    "author": "Reba McEntire"
  },
  {
    "quote": "The way to get started is to quit talking and begin doing.",
    "author": "Walt Disney"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "You are never too old to set another goal or to dream a new dream.",
    "author": "C.S. Lewis"
  },
  {
    "quote": "Success seems to be connected with action. Successful people keep moving.",
    "author": "Conrad Hilton"
  },
  {
    "quote": "The only limit to our realization of tomorrow is our doubts of today.",
    "author": "Franklin D. Roosevelt"
  },
  {
    "quote": "Every artist was first an amateur.",
    "author": "Ralph Waldo Emerson"
  },
  {
    "quote": "Don't watch the clock; do what it does. Keep going.",
    "author": "Sam Levenson"
  },
  {
    "quote": "The best way to predict the future is to create it.",
    "author": "Peter Drucker"
  },
  {
    "quote": "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.",
    "author": "Steve Jobs"
  },
  {
    "quote": "If you are not willing to risk the usual, you will have to settle for the ordinary.",
    "author": "Jim Rohn"
  },
  {
    "quote": "Act as if what you do makes a difference. It does.",
    "author": "William James"
  },
  {
    "quote": "The future belongs to those who believe in the beauty of their dreams.",
    "author": "Eleanor Roosevelt"
  },
  {
    "quote": "It is never too late to be what you might have been.",
    "author": "George Eliot"
  },
  {
    "quote": "You don't have to be great to start, but you have to start to be great.",
    "author": "Zig Ziglar"
  },
  {
    "quote": "The only place where success comes before work is in the dictionary.",
    "author": "Vidal Sassoon"
  },
  {
    "quote": "The greatest glory in living lies not in never falling, but in rising every time we fall.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "What you do today can improve all your tomorrows.",
    "author": "Ralph Marston"
  },
  {
    "quote": "Keep your eyes on the stars, and your feet on the ground.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Do what you can, with what you have, where you are.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Success is not how high you have climbed, but how you make a positive difference to the world.",
    "author": "Roy T. Bennett"
  },
  {
    "quote": "We are what we repeatedly do. Excellence, then, is not an act, but a habit.",
    "author": "Aristotle"
  },
  {
    "quote": "The only way to achieve the impossible is to believe it is possible.",
    "author": "Charles Kingsleigh"
  },
  {
    "quote": "Hardships often prepare ordinary people for an extraordinary destiny.",
    "author": "C.S. Lewis"
  },
  {
    "quote": "Dream big and dare to fail.",
    "author": "Norman Vaughan"
  },
  {
    "quote": "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.",
    "author": "Roy T. Bennett"
  },
  {
    "quote": "The only impossible journey is the one you never begin.",
    "author": "Tony Robbins"
  },
  {
    "quote": "Strive not to be a success, but rather to be of value.",
    "author": "Albert Einstein"
  },
  {
    "quote": "Happiness is not something ready-made. It comes from your own actions.",
    "author": "Dalai Lama"
  },
  {
    "quote": "Your life does not get better by chance, it gets better by change.",
    "author": "Jim Rohn"
  },
  {
    "quote": "You miss 100% of the shots you don't take.",
    "author": "Wayne Gretzky"
  },
  {
    "quote": "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
    "author": "Joshua J. Marine"
  },
  {
    "quote": "It always seems impossible until it’s done.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "Great things are not done by impulse, but by a series of small things brought together.",
    "author": "Vincent Van Gogh"
  },
  {
    "quote": "Do not wait; the time will never be 'just right.' Start where you stand.",
    "author": "Napoleon Hill"
  },
  {
    "quote": "Go the extra mile. It's never crowded.",
    "author": "Wayne Dyer"
  },
  {
    "quote": "Failure is simply the opportunity to begin again, this time more intelligently.",
    "author": "Henry Ford"
  },
  {
    "quote": "Action is the foundational key to all success.",
    "author": "Pablo Picasso"
  },
  {
    "quote": "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "author": "Winston S. Churchill"
  },
  {
    "quote": "The best time to plant a tree was 20 years ago. The second best time is now.",
    "author": "Chinese Proverb"
  },
  {
    "quote": "Dream as if you’ll live forever. Live as if you’ll die today.",
    "author": "James Dean"
  },
  {
    "quote": "The harder the conflict, the more glorious the triumph.",
    "author": "Thomas Paine"
  },
  {
    "quote": "I can’t change the direction of the wind, but I can adjust my sails to always reach my destination.",
    "author": "Jimmy Dean"
  },
  {
    "quote": "You must be the change you wish to see in the world.",
    "author": "Mahatma Gandhi"
  },
  {
    "quote": "Courage is not the absence of fear, but the triumph over it.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "Start where you are. Use what you have. Do what you can.",
    "author": "Arthur Ashe"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "It is our attitude at the beginning of a difficult task which, more than anything else, will affect its successful outcome.",
    "author": "William James"
  },
  {
    "quote": "Nothing is impossible, the word itself says 'I'm possible!'",
    "author": "Audrey Hepburn"
  },
  {
    "quote": "To be a champion, I think you have to see the big picture.",
    "author": "Summer Sanders"
  },
  {
    "quote": "Keep going. Be all in.",
    "author": "Bryan Hutchinson"
  },
  {
    "quote": "Whether you think you can or you think you can't, you're right.",
    "author": "Henry Ford"
  },
  {
    "quote": "All our dreams can come true if we have the courage to pursue them.",
    "author": "Walt Disney"
  },
  {
    "quote": "You have power over your mind – not outside events. Realize this, and you will find strength.",
    "author": "Marcus Aurelius"
  },
  {
    "quote": "Success isn't about how much money you make, it's about the difference you make in people's lives.",
    "author": "Michelle Obama"
  },
  {
    "quote": "Do not be embarrassed by your failures, learn from them and start again.",
    "author": "Richard Branson"
  },
  {
    "quote": "I’ve failed over and over and over again in my life. And that is why I succeed.",
    "author": "Michael Jordan"
  },
  {
    "quote": "In the middle of every difficulty lies opportunity.",
    "author": "Albert Einstein"
  }
];

const API_URL = 'http://localhost:5000/api';

function Login({ onLogin }) {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState('');
  const [userId, setUserId] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [showOldLoginMessage, setShowOldLoginMessage] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const navigate = useNavigate();
  const [currentQuote, setCurrentQuote] = useState({});
  const [showRegularLoginWarning, setShowRegularLoginWarning] = useState(false);

  useEffect(() => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setCurrentQuote(randomQuote);
  }, []);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleSubmit = async (e, withVerification) => {
    e.preventDefault();
    setError('');
    setShowOldLoginMessage(false);

    try {
      if (!withVerification) {
        // Regular login
        const response = await login({ usernameOrEmail, password });
        onLogin(response.data.user);
        setShowSuccessDialog(true);
      } else if (!isVerificationStep) {
        // First step of verification login
        const response = await loginWithVerification({ usernameOrEmail, password });
        setMaskedEmail(response.data.maskedEmail);
        setUserId(response.data.userId);
        setIsVerificationStep(true);
      } else {
        // Second step of verification login
        const response = await verifyCode({ userId, code: verificationCode });
        onLogin(response.data.user);
        navigate('/');
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.response && error.response.status === 429) {
        setCountdown(60);
      }
      setError(error.response?.data?.error || error.message || 'An error occurred during login');
    }
  };

  const handleResendCode = async () => {
    try {
      await loginWithVerification({ usernameOrEmail, password });
      setError('');
    } catch (error) {
      console.error('Error resending code:', error);
      setError('Failed to resend verification code');
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate('/');
  };

  const handleRegularLogin = async (e) => {
    e.preventDefault();
    if (!LOGIN_WITHOUT_EMAIL_ENABLED) {
      setError('Regular login is currently disabled. Please use login with verification.');
      return;
    }
    setError('');
    setShowRegularLoginWarning(false);

    try {
      const response = await login({ usernameOrEmail, password });
      setShowRegularLoginWarning(true);
      sessionStorage.setItem('tempUserData', JSON.stringify(response.data.user));
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred during login');
    }
  };

  const handleWarningAcknowledgment = () => {
    setShowRegularLoginWarning(false);
    const userData = JSON.parse(sessionStorage.getItem('tempUserData'));
    if (userData) {
      onLogin(userData);
      sessionStorage.removeItem('tempUserData');
      navigate('/');
    } else {
      setError('Login data not found. Please try again.');
    }
  };

  return (
    <Root>
      <StyledPaper elevation={3}>
        <LogoText variant="h1">
          PIM BY ELI
        </LogoText>
        <Title variant="h4">
          Welcome Back
        </Title>
        {error && <ErrorText>{error}</ErrorText>}
        <form onSubmit={(e) => handleSubmit(e, true)}>
          {!isVerificationStep ? (
            <>
              <StyledTextField
                fullWidth
                label="Username or Email"
                value={usernameOrEmail}
                onChange={(e) => setUsernameOrEmail(e.target.value)}
                required
                variant="outlined"
              />
              <StyledTextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                variant="outlined"
              />
              <ButtonContainer>
                {LOGIN_WITHOUT_EMAIL_ENABLED && (
                  <RegularLoginButton
                    type="button"
                    variant="contained"
                    onClick={handleRegularLogin}
                    fullWidth
                  >
                    Login
                  </RegularLoginButton>
                )}
                <VerificationLoginButton
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Login with Verification
                </VerificationLoginButton>
              </ButtonContainer>
            </>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                Enter the verification code sent to {maskedEmail}
              </Typography>
              <StyledTextField
                fullWidth
                label="Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
                margin="normal"
              />
              {countdown > 0 ? (
                <Typography variant="body2">
                  You can request a new code in {countdown} seconds
                </Typography>
              ) : (
                <Button onClick={handleResendCode} disabled={countdown > 0}>
                  Resend Code
                </Button>
              )}
              <VerificationLoginButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2 }}
              >
                Verify
              </VerificationLoginButton>
            </>
          )}
        </form>
        <QuoteBox>
          <QuoteText variant="body1">"{currentQuote.quote}"</QuoteText>
          <QuoteAuthor variant="body2">- {currentQuote.author}</QuoteAuthor>
        </QuoteBox>
      </StyledPaper>
      <Dialog open={showRegularLoginWarning} onClose={() => setShowRegularLoginWarning(false)}>
        <DialogTitle>Important Notice</DialogTitle>
        <DialogContent>
          <Typography>
            Regular login will be unavailable soon. Please ensure your email is up to date for the new verification process.
            This login method will be deprecated in the next few days.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningAcknowledgment} color="primary">
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showSuccessDialog} onClose={handleCloseSuccessDialog}>
        <DialogTitle>Login Successful</DialogTitle>
        <DialogContent>
          <Typography>
            You have successfully logged in. Please note that a new verification system will be implemented in the next 24 hours. 
            Ensure your email address is up to date as it will be required for the new login process.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
}

export default Login;