import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MapPin, Package, Zap, BarChart2, Bell, MessageSquare } from 'lucide-react';
import { getTotalLocations, getTotalOptimization, getRecentUpdates, getInventoryHistory } from '../services/api';
import { formatDistanceToNow } from 'date-fns';
import ReactECharts from 'echarts-for-react';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: 8,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
  cursor: 'pointer',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const CardContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#2c3e50',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 700,
  fontSize: '2rem',
}));

function Home() {
  const navigate = useNavigate();
  const [totalLocations, setTotalLocations] = useState(null);
  const [totalInventory, setTotalInventory] = useState(null);
  const [optimizationLevel, setOptimizationLevel] = useState(null);
  const [recentUpdates, setRecentUpdates] = useState([]);
  const [inventoryHistory, setInventoryHistory] = useState([]);

  useEffect(() => {
    fetchTotalLocationsAndInventory();
    fetchOptimizationLevel();
    fetchRecentUpdates();
    fetchInventoryHistory();
  }, []);

  const fetchTotalLocationsAndInventory = async () => {
    try {
      const data = await getTotalLocations();
      setTotalLocations(data.totalLocations);
      setTotalInventory(data.totalInventory);
    } catch (error) {
      console.error('Error fetching data:', error);
      setTotalLocations(0);
      setTotalInventory(0);
    }
  };

  const fetchOptimizationLevel = async () => {
    try {
      const data = await getTotalOptimization();
      setOptimizationLevel(data.optimizationLevel);
    } catch (error) {
      console.error('Error fetching optimization level:', error);
      setOptimizationLevel('0%');
    }
  };

  const fetchRecentUpdates = async () => {
    try {
      const data = await getRecentUpdates();
      setRecentUpdates(data);
    } catch (error) {
      console.error('Error fetching recent updates:', error);
      setRecentUpdates([]);
    }
  };

  const fetchInventoryHistory = async () => {
    try {
      const data = await getInventoryHistory();
      setInventoryHistory(data);
    } catch (error) {
      console.error('Error fetching inventory history:', error);
      setInventoryHistory([]);
    }
  };

  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const inventoryChartOption = {
    title: { text: 'Total Inventory History' },
    tooltip: { trigger: 'axis' },
    xAxis: { 
      type: 'category', 
      data: inventoryHistory.map(item => item.date).sort((a, b) => new Date(a) - new Date(b))
    },
    yAxis: { type: 'value', name: 'Total Inventory' },
    series: [{
      data: inventoryHistory
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map(item => parseInt(item.total_inventory)),
      type: 'line',
      smooth: true
    }]
  };

  const handleCardClick = (route) => {
    navigate(route);
  };

  return (
    <StyledContainer maxWidth="lg">
      <Title variant="h4" component="h1" gutterBottom align="center">
        Inventory Management Dashboard
      </Title>
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard onClick={() => handleCardClick('/locations')}>
            <CardTitle>
              <MapPin size={24} />
              Total Locations
            </CardTitle>
            <CardContent>
              <Typography variant="h3" align="center">
                {totalLocations !== null ? totalLocations : <CircularProgress size={24} />}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard onClick={() => handleCardClick('/optimize')}>
            <CardTitle>
              <Package size={24} />
              Total Inventory
            </CardTitle>
            <CardContent>
              <Typography variant="h3" align="center">
                {totalInventory !== null ? totalInventory : <CircularProgress size={24} />}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard>
            <CardTitle>
              <Zap size={24} />
              Optimization Level
            </CardTitle>
            <CardContent>
              <Typography variant="h3" align="center">
                {optimizationLevel !== null ? optimizationLevel : <CircularProgress size={24} />}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={8}>
          <StyledCard>
            <CardTitle>
              <BarChart2 size={24} />
              Inventory Overview
            </CardTitle>
            <CardContent>
              {inventoryHistory.length > 0 ? (
                <ReactECharts option={inventoryChartOption} style={{ height: 300 }} />
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height={300}>
                  <CircularProgress />
                </Box>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardTitle>
              <Bell size={24} />
              Recent Updates
            </CardTitle>
            <CardContent>
              <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                {recentUpdates.length > 0 ? (
                  recentUpdates.map((update) => (
                    <Box key={update.created_at} mb={2}>
                      <Typography variant="body2">{update.text}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {formatRelativeTime(update.created_at)}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography color="textSecondary">No recent updates available</Typography>
                )}
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}

export default Home;
