import React, { useState, useEffect } from 'react';
import { Typography, Container, Grid, Paper, TextField, Button, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { getSalesData, getLocations } from '../services/api';
import { useNavigate } from 'react-router-dom';

const Sales = () => {
    const [salesData, setSalesData] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [itemTypes, setItemTypes] = useState(['G', 'M', 'G2', 'M2']);
    const [locations, setLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const today = new Date().toISOString().split('T')[0];
                const oneYearAgo = new Date();
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                const earliestDate = oneYearAgo.toISOString().split('T')[0];
                const locationsData = await getLocations();
                setLocations(locationsData.map(location => location.name));
                setSelectedLocations(locationsData.map(location => location.name)); // Select all locations by default
                const data = await getSalesData(earliestDate, today, itemTypes, locationsData.map(location => location.name));
                console.log('Fetched initial sales data:', JSON.stringify(data, null, 2));
                setSalesData(data);
                setStartDate(earliestDate);
                setEndDate(today);
            } catch (err) {
                console.error('Error fetching initial data:', err);
                if (err.response && err.response.status === 401) {
                    navigate('/login');
                } else {
                    setError('Failed to fetch initial data');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    const fetchSalesData = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getSalesData(startDate, endDate, itemTypes, selectedLocations);
            console.log('Fetched sales data:', data);
            setSalesData(data);
        } catch (err) {
            console.error('Error fetching sales data:', err);
            if (err.response && err.response.status === 401) {
                navigate('/login');
            } else {
                setError('Failed to fetch sales data');
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (startDate && endDate) {
            fetchSalesData();
        }
    }, [itemTypes, selectedLocations]);

    const getChartOption = () => {
        if (!salesData || typeof salesData !== 'object' || Object.keys(salesData).length === 0) {
            console.log('Invalid or empty salesData:', salesData);
            return {};
        }

        const locations = Object.keys(salesData);
        const allDates = new Set();
        
        locations.forEach(location => {
            if (Array.isArray(salesData[location])) {
                salesData[location].forEach(item => {
                    if (item && item.date) {
                        allDates.add(item.date);
                    }
                });
            } else {
                console.warn(`Data for location ${location} is not an array:`, salesData[location]);
            }
        });

        const sortedDates = Array.from(allDates).sort();

        const series = locations.map(location => ({
            name: location,
            type: 'line',
            data: sortedDates.map(date => {
                if (Array.isArray(salesData[location])) {
                    const dataPoint = salesData[location].find(item => item.date === date);
                    return [date, dataPoint ? dataPoint.quantity : 0];
                } else {
                    console.warn(`Invalid data for location ${location}`);
                    return [date, 0];
                }
            })
        }));

        return {
            title: {
                text: 'Daily Item Sales by Location'
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    const date = params[0].axisValue;
                    let result = `${date}<br/>`;
                    params.forEach(param => {
                        result += `${param.seriesName}: ${param.value[1]} items<br/>`;
                    });
                    return result;
                }
            },
            legend: {
                data: locations
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: sortedDates
            },
            yAxis: {
                type: 'value',
                name: 'Items Sold'
            },
            series: series
        };
    };

    const getTotalSales = () => {
        return Object.entries(salesData).map(([location, data]) => ({
            location,
            total: Array.isArray(data)
                ? data.reduce((sum, item) => sum + item.quantity, 0)
                : 0
        }));
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>Sales Statistics</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Item Types</InputLabel>
                        <Select
                            multiple
                            value={itemTypes}
                            onChange={(e) => setItemTypes(e.target.value)}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {['G', 'M', 'G2', 'M2'].map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Locations</InputLabel>
                        <Select
                            multiple
                            value={selectedLocations}
                            onChange={(e) => setSelectedLocations(e.target.value)}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {locations.map((location) => (
                                <MenuItem key={location} value={location}>
                                    {location}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" onClick={fetchSalesData} style={{ marginTop: '1rem' }}>
                Update Sales Data
            </Button>
            {loading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {!loading && !error && Object.keys(salesData).length > 0 && (
                <>
                    <Paper style={{ marginTop: '2rem', padding: '1rem' }}>
                        <ReactECharts option={getChartOption()} style={{ height: '400px' }} />
                    </Paper>
                    <Paper style={{ marginTop: '2rem', padding: '1rem' }}>
                        <Typography variant="h6" gutterBottom>Total Items Sold by Location</Typography>
                        <Grid container spacing={2}>
                            {getTotalSales().map(({ location, total }) => (
                                <Grid item xs={12} sm={6} md={4} key={location}>
                                    <Box border={1} borderRadius={2} p={2}>
                                        <Typography variant="subtitle1">{location}</Typography>
                                        <Typography variant="h6">{total} items</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </>
            )}
        </Container>
    );
};

export default Sales;